<template>
  <div class="w-100" :key="update">
    <b-modal v-if="showEducationModal || editMode" v-model="showEducationModal" no-close-on-backdrop no-close-on-esc size="xl" :noCloseOnBackdrop="true">
      <template #modal-header="">
        <h4 class=" mb-1 instituteTitle_block">
          Education Details
        </h4>
        <b-button size="sm" class="closeEduModal" @click="closeEducationModal()">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>
      <div class="row w-100 educationForm m-0">
        <ValidationObserver ref="educationForm" class="row w-100 m-0">
          <div class="form-group col-12">
              <label for="Education/ Class">Education / Class&nbsp;<label class="primary-color font-size-16">*</label></label>
              <ValidationProvider name="Education/ Class"  v-slot="{ errors }" rules='required|min:1'>
                  <multiselect
                  v-model="educationForm['education']"
                  :options="cvClassOptions"
                  placeholder="Class"
                  label="class_name"
                  @select="classSelected"
                  track-by="class_name">
              </multiselect>
              <!-- <input v-model="educationForm['education']" type="text" class="form-control mb-0" id="Education/ Class"> -->
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
          </div>

          <div class="form-group col-12">
              <label for="School / Institute name">School / Institute name&nbsp;<label class="primary-color font-size-16">*</label></label>
              <ValidationProvider name="School / Institute name"  v-slot="{ errors }" rules='required|min:2'>
              <multiselect
                v-model="studentSchool"
                :options="cvSchoolOptions"
                placeholder="Select School"
                track-by="org_id"
                ref="multiselectschool"
                @search-change="SearchSchoolList($event)"
                @select="addSchoolShow= false; schoolForm = {}"
                :custom-label="schNameWithCityCountry">
                <span slot="noResult">No school found<br> (Consider changing the search query) or
                  <br><hr>
                  <span @click="showAddSchool()" class="schoolAdd">
                    <i title='Add School' class="fa-solid fa-circle-plus"></i> Click here to add school</span>
                </span>
              </multiselect>

              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
          </div>

          <!-- School Add -->
          <template v-if="addSchoolShow">
            <ValidationObserver ref="schoolForm" class="row w-100 pl-2">
              <div class="col-md-3 col-lg-3 col-sm-12" v-for="(item, index) in cvSchoolForm" :key="index">
                <label :for="item.label">{{item.label}}</label>
                <ValidationProvider :name="item.label" rules="required|min:2" v-slot="{ errors }">
                  <input v-model="schoolForm[item.key]" :type="item.type" class="form-control mb-0" :id="item.label">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </ValidationObserver>

            <div class="form-group w-100 text-right mt-3" style="margin-bottom:50px">
              <button class="btn btn-primary" type="button" @click="addSchool" style="float: right;">
                Add School
              </button>
              <b-button type="button" @click="addSchoolShow = false" style="float: right; margin-right:10px">
                Cancel
              </b-button>
            </div>
          </template>
          <!-- School Add End -->

          <div class="form-group col-12">
            <label for="Degree/Diploma/Certificate">Class / Degree / Diploma / Certificate&nbsp;<label class="primary-color font-size-16">*</label></label>
            <ValidationProvider name="Degree/Diploma/Certificate"  v-slot="{ errors }" rules='required|min:1'>
            <input v-model="educationForm['course_name']" :readonly="!educationForm['education']" type="text" class="form-control mb-0" id="Degree/Diploma/Certificate">
            <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="form-group col-12">
            <label for="Board / University / Examination Body">Board / University / Examination Body&nbsp;<label class="primary-color font-size-16">*</label></label>
            <ValidationProvider name="Board / University / Examination Body"  v-slot="{ errors }" rules='required|min:2'>
            <template v-if="educationForm['education'] && educationForm['education'].class_name <= 12">
              <multiselect
                v-model="educationForm['examination_body']"
                :options="cvExamBodyOptions"
                placeholder="Select Board">
              </multiselect>
            </template>
            <template v-else>
              <input v-model="educationForm['examination_body']" type="text" :readonly="!educationForm['education']" class="form-control mb-0" id="Board / University / Examination Body">
            </template>
            <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="form-group col-12 col-sm-6 col-md-6 col-lg-6">
            <label for="Start date">Term Start Date</label>
            <input v-model="educationForm['start_date']" type="date" class="form-control mb-0" id="Start Date">
          </div>

          <div class="form-group col-12 col-sm-6 col-md-6 col-lg-6">
            <label for="End date">Term End Date</label>
            <input v-model="educationForm['end_date']" type="date" class="form-control mb-0" id="End Date">
          </div>

          <!-- Subject -->
          <div class="form-group col-12 markRow" style="margin-top: 40px;">
            <label for="Aggregated percentage">Marksheet</label>
          </div>

          <div class="form-group col-6 markRow">
            <label for="Aggregated percentage">Subject</label>
            <input v-model="vmSubjectForm.name" type="text" class="form-control mb-0" id="Subject">
          </div>

          <div class="form-group col-6 markRow">
            <label for="Aggregated percentage">Marks / Grades</label>
            <input v-model="vmSubjectForm.marks" type="text" class="form-control mb-0" id="marks">
            <span class="addIcon" @click="addSubject()"><i class="fa-solid fa-circle-plus"></i></span>
          </div>
          <!-- subject form -->

          <!-- Subject list -->
          <template v-if="educationForm && educationForm.subjects && educationForm.subjects.length">
            <template v-for="(subject, index) of educationForm.subjects" >
              <div class="form-group col-6" :key="index+'sub'">
                <label for="Aggregated percentage">Subject </label>
                <input v-model="subject.name" type="text" class="form-control mb-0" id="Subject">
              </div>

              <div class="form-group col-6 markRow" :key="index+'subMark'">
                <label for="Aggregated percentage">Marks / Grades </label>
                <input v-model="subject.marks" type="text" class="form-control mb-0" id="marks">
                <span class="addIcon" @click="educationForm.subjects.splice(index, 1)"><i class="fa-solid fa-circle-xmark"></i></span>
              </div>
              </template>
          </template>

          <div class="form-group col-6" style="margin-left: auto; margin-right: 0;">
            <label for="Aggregated percentage">Aggregated percentage/ Grade</label>
            <input v-model="educationForm['percentage']" type="text" class="form-control mb-0" id="Aggregated percentage">
          </div>
          <!-- subject list END -->

          <div class="form-group col-12" style="margin-top: 40px;">
            <label for="Document">Upload Certificates, Marksheets & Transcripts (Only PDFs are acceptable, size less than 5mb)</label>
          </div>

          <!-- Document Start -->
          <template>
            <div class="row w-100 mt-0 pt-0 pl-2">
              <div class="col-md-4 col-sm-12 pt-0 mb-0">
                <label for="attachmentType">Select Document Type</label>
                <ValidationProvider name="attachmentType"  v-slot="{ errors }">
                  <multiselect
                    v-model="cvAttachmentObj.doc_type"
                    :options="cvAttachmentTagOptions">
                  </multiselect>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-md-5 col-sm-12 pt-0 mb-0">
                <label for="Document name">Document name</label>
                <ValidationProvider name="Document name"  v-slot="{ errors }">
                  <input v-model="cvAttachmentObj.doc_name" placeholder="Enter Document name" type="text" class="form-control mb-0" id="Document name">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-md-3 col-sm-12 pt-4 mb-0">
                <input type="file" accept="application/pdf,image/x-eps" class="p-3" id="AttachmentFileInput" @change="onEducationAttatachmentChange($event)">
              </div>
            </div>
          </template>

          <template v-if="educationForm.attachments && educationForm.attachments.length">
            <template v-for="(attachment, index) of educationForm.attachments">
              <div class="row w-100 p-2" :key="index+'image'">
              <hr class="w-100">
                <div class="col-md-4 col-sm-12 pt-0 mb-0">
                  <label for="attachmentType">Document Type</label>
                  <ValidationProvider name="attachmentType"  v-slot="{ errors }">
                    <multiselect
                      v-model="attachment.doc_type"
                      :options="cvAttachmentTagOptions">
                    </multiselect>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="form-group col-md-5 col-sm-12 pt-0 mb-0">
                  <label :for="'DocumentName'+index">Document name</label>
                  <ValidationProvider name="Document name"  v-slot="{ errors }">
                    <input v-model="attachment.doc_name" placeholder="Enter Document name" type="text" class="form-control mb-0" :id="'DocumentName'+index">
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="form-group col-md-3 col-sm-12 pt-4 mb-0 attachmentCol">
                  <label for="AttachmentFileInput" class="attachmentAddIcon pointer pt-2" @click="educationForm.attachments.splice(index, 1)"><i class="fa-solid fa-circle-xmark"></i></label>
                  <img v-if="attachment.content && attachment.content.includes('application') || attachment.type && attachment.type.includes('pdf')" :src="pdfLogo" alt="not found" @click="openFile(attachment.url)">
                  <img v-if="attachment.content && attachment.content.includes('image') || attachment.type && attachment.type.includes('image')" :src="attachment.url" alt="not found" @click="openFile(attachment.url)">
                </div>
              </div>
            </template>
          </template>
          <!-- Document End -->
          <div class="row w-100 rightAlignDiv mb-4 mt-4 ">
            <b-button size="sm" variant="primary" @click="submitEducation()">
              Save Education
            </b-button>
          </div>
        </ValidationObserver>

        <!-- Extra curricular activity Start -->
        <template>
          <h4 class="form-group mt-4 col-12 ml-0 pl-0">
            Extra Curricular
          </h4>
          <hr class="w-100" style="border-color:#e1e1e1">
          <ValidationObserver ref="extraCurrucularForm" class="row w-100 mt-4">
            <div class="form-grou col-12 col-sm-6 col-lg-6">
              <label for="exc_type">Type</label>
              <ValidationProvider name="Type"  v-slot="{ errors }" rules='required|min:1'>
                <multiselect :options="cvExtraCurricularTypeList" v-model="vmExtraCurricularForm['exc_type']" id="exc_type">
                </multiselect>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="form-group col-6">
              <label for="exc_title">Title</label>
              <ValidationProvider name="Title"  v-slot="{ errors }" rules='required|min:5|max:50'>
                <input v-model="vmExtraCurricularForm['exc_title']" type="text" class="form-control mb-0" id="exc_title">
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="form-group col-6">
              <label for="exc_org">Organisation</label>
                <input v-model="vmExtraCurricularForm['exc_org']" type="text" class="form-control mb-0" id="exc_org">
            </div>

            <div class="form-group col-12">
              <label for="exc_desc">Descritpion(Please make pointers)</label>
              <ValidationProvider name="Descritpion" rules='required|min:50|max:500' v-slot="{ errors }">
                <textarea autogrow v-model="vmExtraCurricularForm['exc_desc']" style="height:unset;" type="textarea" class="form-control mb-0" id="exc_desc">
                </textarea>
                <span v-if="vmExtraCurricularForm['exc_desc']" :class="(vmExtraCurricularForm['exc_desc'].length < 50 || vmExtraCurricularForm['exc_desc'].length > 500) ? 'text-danger': 'text-success'">{{vmExtraCurricularForm['exc_desc'].length}}/ 500</span>
                <br>
                <template v-if="vmExtraCurricularForm['exc_desc']">
                  <span class="text-danger" v-if="vmExtraCurricularForm['exc_desc'].length < 50">{{ errors[0] ? 'Min 50 charactrers are required' : '' }}</span>
                  <span class="text-danger" v-if="vmExtraCurricularForm['exc_desc'].length > 499">{{ errors[0] ? 'Max 500 charactrers are allowed' : '' }}</span>
                </template>
                <template v-else>
                  <span class="text-danger">{{ errors[0] }}</span>
                </template>
              </ValidationProvider>
            </div>

            <div class="form-group col-12 markRow">
              <label for="exc_video_link">Video Link (Only Youtube links accepted)</label>
              <ValidationProvider name="Video Link"  v-slot="{ errors }">
                <input v-model="vmExtraCurricularForm.excVideoLink" type="text" class="form-control mb-0" id="exc_video_link">
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
                <span class="addIcon" @click="addExcVideoLink(vmExtraCurricularForm)"><i class="fa-solid fa-circle-plus"></i></span>
            </div>

            <template v-if="vmExtraCurricularForm.exc_video_link && vmExtraCurricularForm.exc_video_link.length">
              <div v-for="(videoLinkObj, index) of vmExtraCurricularForm.exc_video_link" :key="index+'vmExtraCurricularForm.excVideoLink'" class="form-group col-12 markRow">
                <label :for="'exc_video_linkForm'+index">Video Link (Only Youtube links accepted)</label>
                <ValidationProvider name="Video Link"  v-slot="{ errors }">
                  <input v-model="videoLinkObj.url" type="text" class="form-control mb-0" :id="'exc_video_linkForm'+index">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <span class="addIcon" @click="vmExtraCurricularForm.exc_video_link.splice(index, 1)"><i class="fa-solid fa-circle-xmark"></i></span>
              </div>
            </template>

            <div class="row col-12 w-100 ml-1">
              <h6 class="educationTitle w-100 mb-2">{{showcaseAttachments}}&nbsp;&nbsp;
                <label for="excAttachmentFileInput" class="pointer primary-color">(Click here to upload)&nbsp;&nbsp;<i class="fa-solid fa-upload"></i></label>
                <input type="file" accept="application/pdf,image/x-eps" class="dontShow" id="excAttachmentFileInput" @change="onExtraCurricularAttatachmentChange($event, vmExtraCurricularForm, 'excAttachmentFileInput')">
              </h6>
              <template v-if="vmExtraCurricularForm.exc_attach && vmExtraCurricularForm.exc_attach.length">
                <template v-for="(attachment, index) of vmExtraCurricularForm.exc_attach">
                  <div class="attachmentCol" :key="index+'EXTattachments'">
                    <img v-if="attachment.content && attachment.content.includes('pdf') || attachment.type && attachment.type.includes('pdf')" :src="pdfLogo" alt="not found" @click="openFile(attachment.url)">
                    <img v-else :src="attachment.url" alt="not found" @click="openFile(attachment.url)">
                    <span class="addIcon extraCloseIcon" @click="vmExtraCurricularForm.exc_attach.splice(index, 1)"><i class="fa-solid fa-circle-xmark"></i></span>
                  </div>
                </template>
              </template>
            </div>

            <div class="row w-100 pt-4 mb-4 rightAlignDiv">
              <b-button @click="addExtraCurricularActivity()" variant="primary" type="button">Save Extra Curricular</b-button>
            </div>
          </ValidationObserver>
          <!--  LIST -->
          <hr class="w-100">

          <template v-if="extraCurricularList && extraCurricularList.length">
            <ValidationObserver v-for="(extraCurricular, index) of extraCurricularList" :ref="'extraCurrucularForm'+index" :key="'extraCurricularList'+index" class="row w-100 mt-4">
              <div class="form-group col-6">
                <label :for="'exc_title'+index">Title</label>
                <ValidationProvider name="Title"  v-slot="{ errors }" rules='required|min:5|max:50'>
                  <input v-model="extraCurricular['exc_title']" type="text" class="form-control mb-0" :id="'exc_title'+index">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div class="form-group col-6">
                <label :for="'exc_type'+index">Type</label>
                <ValidationProvider name="Type"  v-slot="{ errors }" rules='required|min:1'>
                  <multiselect :options="cvExtraCurricularTypeList" v-model="extraCurricular['exc_type']" :id="'exc_type'+index">
                  </multiselect>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div class="form-group col-12">
                <label :for="'exc_desc'+index">Descritpion</label>
                <ValidationProvider name="Descritpion" rules='required|min:50|max:500' v-slot="{ errors }">
                  <textarea autogrow v-model="extraCurricular['exc_desc']" style="height:unset;" type="textarea" class="form-control mb-0" :id="'exc_desc'+index">
                  </textarea>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div class="form-group col-12 markRow">
                <label :for="'exc_video_link'+index">Video Link (Only Youtube links accepted)</label>
                <ValidationProvider name="Video Link"  v-slot="{ errors }">
                  <input v-model="extraCurricular.excVideoLink" type="text" class="form-control mb-0" :id="'exc_video_link'+index">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                  <span class="addIcon" @click="addExcVideoLink(extraCurricular)"><i class="fa-solid fa-circle-plus"></i></span>
              </div>

              <template v-if="extraCurricular.exc_video_link && extraCurricular.exc_video_link.length">
                <div v-for="(videoLinkObj, excVideoLinkIndex) of extraCurricular.exc_video_link" :key="excVideoLinkIndex+'ExcListVideoLink'" class="form-group col-12 markRow">
                  <label :for="'exc_video_link'+index">{{videoLinks}}</label>
                  <ValidationProvider name="Video Link"  v-slot="{ errors }">
                    <input v-model="videoLinkObj.url" type="text" class="form-control mb-0" :id="'exc_video_link'+index">
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <span class="addIcon" @click="extraCurricular.exc_video_link.splice(index, 1)"><i class="fa-solid fa-circle-xmark"></i></span>
                </div>
              </template>

              <div class="row col-12 w-100 ml-1">
                <h6 class="educationTitle w-100 mb-2">{{showcaseAttachments}}&nbsp;&nbsp;
                  <label :for="'excAttachmentFileInput'+index" class="pointer primary-color">(Click to upload)&nbsp;&nbsp;<i class="fa-solid fa-upload"></i></label>
                  <input type="file" accept="application/pdf,image/x-eps" class="dontShow" :id="'excAttachmentFileInput'+index" @change="onExtraCurricularAttatachmentChange($event, extraCurricular, 'excAttachmentFileInput'+index)">
                </h6>
                <template v-if="extraCurricular.exc_attach && extraCurricular.exc_attach.length">
                  <template v-for="(attachment, excAttachIndex) of extraCurricular.exc_attach">
                    <div class="attachmentCol" :key="excAttachIndex+'EXTattachments'">
                      <img v-if="attachment.content && attachment.content.includes('pdf') || attachment.type && attachment.type.includes('pdf')" :src="pdfLogo" alt="not found" @click="openFile(attachment.url)">
                      <img v-else :src="attachment.url" alt="not found" @click="openFile(attachment.url)">
                      <span class="extraCloseIcon" @click="extraCurricular.exc_attach.splice(index, 1)"><i class="fa-solid fa-circle-xmark"></i></span>
                    </div>
                  </template>
                </template>
              </div>
              <div class="row w-100 pt-4 mb-4 rightAlignDiv">
                <b-button @click="deleteExtraCurricularActivity(extraCurricular, index)">Delete</b-button>
                <b-button class="ml-4" variant="primary" @click="editExtraCurricularActivity(extraCurricular, index)">Update Extracurricular</b-button>
              </div>
              <hr class="w-100">
            </ValidationObserver>
          </template>
        </template>
        <!-- Extra curricular activity End -->
      </div>
      <template #modal-footer="">
        <b-button class="ml-4" @click="closeEducationModal()">Close without saving</b-button>
      </template>
    </b-modal>

    <div v-else class="educationCard viewEducationCard" v-for="(item, index) of propEducationList" :key="index+'o'" >
      <div class="iq-card-body multi_education_div p-0 pr-1 ml-2">
        <h4 class=" mb-1 instituteTitle_block">
          <span v-if="SHOW_HTML_FLAG" class="instituteTitle"><i class="fa fa-school" aria-hidden="true"></i>&nbsp;{{item.institute_name}}</span>
          <span v-else class="instituteTitle"><span v-if="item.education && item.education.class_name">{{item.education.class_name | addSuffixToClass}}</span></span><br>

          <span v-if="propUserView" class="educationAddIcon" @click="editEducationForm(item)"><i class="fa-solid ri-pencil-line"></i></span>
        </h4>
        <p class=" mb-1">
          <span>
            {{item.institute_city ? item.institute_city : ''}}
            {{item.institute_state ? ", " + item.institute_state : ' '}}
            {{item.institute_country ? ", " + item.institute_country : ''}}
          </span><br>
          <span v-if="item.education && item.education.class_name">{{item.education.class_name | addSuffixToClass}}</span>
          <span>{{item.examination_body ? '('+item.examination_body+')' : ''}}</span><br>
          <span>{{item['start_date'] | dateFormatMMYYYY}}</span> -  <span>{{item['end_date'] | dateFormatMMYYYY}}</span>
        </p>

        <div class="pl-0">
          <template v-if="item.subjects && item.subjects.length">
            <div class="row mb-1">
              <div class="col-6" v-if="!propFromResumeBuilder">
                <p class="m-0">
                  Subject
                </p>
              </div>
              <div class="col-6 mb-1" v-if="!propFromResumeBuilder">
                <p class="m-0">
                  Marks / Grades
                </p>
              </div>
              <hr class="w-100">

              <template v-for="(subject, index) of item.subjects" >
                <div class="col-6" v-if="!propFromResumeBuilder" :key="index+index+'subject'">
                  <p class="m-0">
                    <span>{{subject.name}}</span>
                  </p>
                </div>
                <div class="col-6" v-if="!propFromResumeBuilder" :key="index+index+'mark'">
                  <p class="m-0">
                    <span>{{subject.marks}}</span>
                  </p>
                </div>
              </template>

              <div class="col-6">
                <p class="m-0">
                  Aggregate Percentage / Grade
                </p>
              </div>
              <div class="col-6">
                <p class="m-0">
                  {{item['percentage']}}
                </p>
              </div>
            </div>
          </template>
        </div>

        <div class="">
          <template v-if="item.attachments && item.attachments.length">
            <div class="row col-12 mt-4 pl-2">
              <table class="w-100 table_scroll" v-if="!propFromResumeBuilder">
                <tr class="headerRow">
                  <th><h6 class="educationTitle">Document Name</h6></th>
                  <th><h6 class="educationTitle">Type</h6></th>
                  <th><h6 class="educationTitle">Document</h6></th>
                </tr>
                <template v-for="(attachment, index) of item.attachments">
                  <tr :key="index+'image'">
                    <td>
                      <p class="educationTitle "><span>{{attachment.doc_type ? attachment.doc_type : cvAttachmentTagListObj.OtherDocuments}}</span></p>
                    </td>
                    <td>
                      <p class="educationTitle"><span>{{attachment.doc_name}}</span></p>
                    </td>
                    <td class="attachmentCol pointer mt-2">
                      <img v-if="attachment.content && attachment.content.includes('application') || attachment.type && attachment.type.includes('pdf')" :src="pdfLogo" alt="not found" @click="openFile(attachment.url)">
                      <img v-if="attachment.content && attachment.content.includes('image') || attachment.type && attachment.type.includes('image')" :src="attachment.url" alt="not found" @click="openFile(attachment.url)">
                    </td>
                  </tr>
                </template>
              </table>
            </div>
          </template>
        </div>

        <div class="row w-100 ml-0 mt-4" v-if="propExcObjList[item.id] && propExcObjList[item.id].length">
          <h6 class="w-100">ExtraCurricular Activitites</h6><br>
          <template v-for="(exc, index) of propExcObjList[item.id]">
            <div class="col-12 ml-0 pl-0" :key="exc.exc_id+index">
              <div>
                <span class="capitalize"><i>{{exc.exc_type}} - {{exc.exc_title}} </i></span><br>
                <p class=""> {{exc.exc_desc}}</p>
              </div>
              <template v-if="exc.exc_video_link && exc.exc_video_link != '[]' && exc.exc_video_link.length">
                <div class="row col-12 mt-2">
                  <p class="mb-2 p-0 w-100 pl-4">
                      - {{videoLinks}}
                  </p>
                  <div class="row w-100 pl-3">
                    <template v-for="(video, index) of  parseToJSON(exc.exc_video_link)">
                      <div v-if="video.image" class="metaPreviewCol pointer" :key="index+'imageMeta'" :title="video.title">
                        <img :src="video.image" @click="openFile(video.url)">
                      </div>
                      <div v-else class="metaPreviewCol pointer text-center" :key="index+'imageMeta'" :title="video.title">
                        <i class="fa-solid fa-video-camera noVideoLinkIcon"></i>
                      </div>
                    </template>
                  </div>
                </div>
              </template>

              <div class="row col-12 mt-2" v-if="exc.exc_attach && exc.exc_attach != '[]' && exc.exc_attach.length">
                <p class="mb-2 p-0 w-100">
                  - {{showcaseAttachments}}
                </p>
                <div class="row w-100 pl-4">
                  <template v-for="(attachment, index) of parseToJSON(exc.exc_attach)">
                    <div class="attachmentCol pointer" :key="index+'EXTattachmentsList'">
                      <img v-if="attachment.content && attachment.content.includes('pdf') || attachment.type && attachment.type.includes('pdf')" :src="pdfLogo" @click="openFile(attachment.url)" :title="getAttachmentName(attachment.url)">
                      <div v-else :src="attachment.url" @click="openFile(attachment.url)" :title="getAttachmentName(attachment.url)">
                        <i class="fa-solid fa-file-text attachmentCol pointer"></i>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <hr class="w-100"/><br/>
    </div>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </div>
</template>
<script>
import { User } from "../../FackApi/api/user.js"
import { UserProfileExc } from "../../FackApi/api/userProfileExc.js"
import { ValidationProvider } from "vee-validate"
import config from "../../Utils/config.js"
import Multiselect from "vue-multiselect"
import pdfLogo from "../../assets_gide/img/logo/pdfLogo.png"
import ApiResponse from "../../Utils/apiResponse.js"
import { Organisations } from "../../FackApi/api/organisation"

export default {
  name: "Education",
  components: {
    ValidationProvider,
    Multiselect
  },
  props: {
    propEducationAddShow: {
      default: false
    },
    propEducationList: {
      default: () => {
        return []
      }
    },
    propExcObjList: {
      default: () => {
        return {}
      }
    },
    propUserView: {
      type: Boolean,
      default: true
    },
    propFromResumeBuilder: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      educationForm: {
        subjects: [],
        videoLinks: [],
        attachments: []
      },
      addEducationShow: false,
      editEducationShow: false,
      vmSubjectForm: {},
      vmExtraCurricularForm: {
        exc_attach: [],
        exc_video_link: []
      },
      showEducationModal: false,
      educationSubmited: false,
      videoLink: null,
      cvClassOptions: config.ClassList,
      cvExamBodyOptions: config.BoardList,
      cvAttachmentTagOptions: [],
      cvAttachmentTagListObj: config.AttachmentTagListObj,
      cvExtraCurricularTypeList: config.ExtraCurricularTypeList,
      editMode: false,
      update: 1,
      pdfLogo: pdfLogo,
      cvAttachmentObj: {},
      cvAttachFilenameWithTagObj: {},
      cvExtraCurricularWithTagObj: {},
      extraCurricularList: [],
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "User Profile Response",
      showcaseAttachments: "Document Showcase (Only PDFs are acceptable, size less than 5mb)",
      videoLinks: "Video Links (Projects, Case Studies, Achivements, Media Coverage)",
      cvSchoolOptions: [],
      cvSchoolForm: [
        { key: "org_name", type: "text", label: "School Name" },
        { key: "org_city", type: "text", label: "School City" },
        { key: "org_state", type: "text", label: "School State" },
        { key: "org_country", type: "text", label: "School Country" }
      ],
      schLimit: 20,
      addSchoolShow: false,
      cvSchoolOptionsBackup: [],
      schoolForm: {},
      studentSchool: null,
      SHOW_HTML_FLAG: false,
      schoolCheckRegx: / school|college|institute|academy|mandir|high|centre|Vidyapeeth|convent|vihar|house|vidhyapit|devshala|niketan|vidhyapith|Vidyapith|institution|Shiksha|bhavan|society|secondary|Vidhyalaya|peeth|guru|dps|Secondary|kendra|kendriya|senior|ashram|campus|sch|public|pub|the|Vidyalaya|sansthan/gmi
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    this.$emit("emitGetOpenEducationFun", this.OpenEduModelFromParent)
    // Show Education if the user is self or when viewed in admission Add/Edit
    if (this.userData.user_id === this.$route.params.user_id ||
      this.userData.user_role === "USERROLE11111" || this.userData.user_role === "USERROLE11118" ||
      this.$route.name.includes("gide.admission") || this.$route.name.includes("gpath_home")
    ) {
      this.SHOW_HTML_FLAG = true
    }

    if (this.propEducationAddShow) {
      this.addEducationShow = true
      this.showEducationModal = true
    }

    Object.keys(this.cvAttachmentTagListObj).map((key) => {
      this.cvAttachmentTagOptions.push(this.cvAttachmentTagListObj[key])
    })

    this.schoolList() // @todo Should be launched in Edit/Add Mode only
  },
  methods: {
    /*eslint-disable */
    schNameWithCityCountry ({ org_name, org_city, org_country, org_state }) {
      return `${org_name} ${org_city ? ', '+ org_city : ''}${org_state ? ', '+ org_state : ''}${org_country ? ', ' + org_country : ''}`
    },
    /**
     * showAddSchool
     */
    showAddSchool () {
      this.addSchoolShow = true
      this.$refs.multiselectschool.toggle()
    },
    /**
     * sendOpenEduModelToParent
     */
    async OpenEduModelFromParent (eduId) {
      // call the editEducationForm() from here 
      const eduObj = this.propEducationList.find(e => e.id == eduId)
      if (eduObj) {
        this.editEducationForm(eduObj)
      }
    },
    /**
     * schoolList
     */
    async schoolList () {
      const filter = {
        limit: this.schLimit,
        type: ["SCH"]
      }

      let schoolResp = await Organisations.organisationList(this, filter)
      if (schoolResp.resp_status) {
        // this.cvSchoolOptions = schoolResp.resp_data.data
        this.cvSchoolOptions = Object.keys(schoolResp.resp_data.data).map((key)=> schoolResp.resp_data.data[key])

        this.cvSchoolOptionsBackup = [ ...this.cvSchoolOptions ]
      }
    },
    /**
     * SearchSchoolList
     */
    async SearchSchoolList (searchString) {
      if (!searchString || searchString.trim().length < 1) {
        this.cvSchoolOptions = [ ...this.cvSchoolOptionsBackup ]
        return
      }

      if (searchString.length < 3) {
        return
      }

      if (searchString.toLowerCase() === "school" || searchString.toLowerCase() === "schoo" || searchString.toLowerCase() === "schoo" || searchString.toLowerCase() === "sch") {
        this.toastMsg = "Please enter your school name."
        this.toastVariant = "danger"
        this.showToast = true
        return
      }

      const filter = {
        // limit: this.schLimit,
        search: searchString.trim(),
        type: ["SCH"]
      }

      let schoolResp = await Organisations.organisationListSearch(this, filter)
      if (schoolResp.resp_status) {
        // this.cvSchoolOptions = schoolResp.resp_data.data
        this.cvSchoolOptions = Object.keys(schoolResp.resp_data.data).map((key)=> schoolResp.resp_data.data[key])
      }
    },
    /**
     * addSchool
    */
    async addSchool () {
      let valid = await this.$refs.schoolForm.validate()
      if (!valid) {
        return
      }

      if (!this.schoolForm.org_name.match(this.schoolCheckRegx)) {
        //If the school names donot contain the regex keywords then return error
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "Please enter valid school name"
        return
      }

      this.schoolForm.type = "SCH"
      this.schoolForm.org_admin_email = process.env.VUE_APP_SUPPORT_EMAIL

      const schoolAddResp = await Organisations.organisationAdd(this, this.schoolForm)
      if (schoolAddResp.resp_status) {
        this.cvSchoolOptions.unshift(schoolAddResp.resp_data)
        this.studentSchool = schoolAddResp.resp_data
        this.addSchoolShow = false
      }
      else {
        ApiResponse.responseMessageDisplay(this, schoolAddResp)
      }
    },
    /**
     * getAttachmentName
     */
    getAttachmentName (url) {
      return url.split("/").pop()
    },
    /**
     * parseToJSON
     */
    parseToJSON (obj) {
      if (typeof obj === "string") {
        obj = JSON.parse(obj)
      }
      return obj
    },
    /**
     * getUserProfileExtraCurricular
     * @tobe used later after structure change
     */
    async getUserProfileExtraCurricular () {
      const UserProfileExcViewResp = await UserProfileExc.user_profile_excView(this, { usp_edu_id: this.educationForm.id })
      if (UserProfileExcViewResp.resp_status) {
        this.extraCurricularList = UserProfileExcViewResp.resp_data.data
      }
    },
    /**
     * openFile
     */
    openFile (url) {
      if (url === "") {
        // empty url means that univ admin has not accepted the application
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "This document can only be viewed after accepting the application"
        return
      }

      window.open(url, "_blank")
    },
    /**
     * classSelected
     */
    classSelected (event) {
      this.educationForm["course_name"] = ""
      this.educationForm["examination_body"] = ""
      if (event.class_name <= 12) {
        this.educationForm["course_name"] = event.class_name
      }
    },
    /**
     * addVideoLink
     */
    addVideoLink () {
      this.educationForm.videoLinks.unshift({ url: this.videoLink })
      this.videoLink = null
    },
    /**
     * editEducationForm
     */
    editEducationForm (item) {
      this.extraCurricularList = []
      this.educationSubmited = false
      this.educationForm = { ...JSON.parse(JSON.stringify(item)) } // bcz education form have sub arr and meda arr that array address is not changed
      delete this.educationForm.extraCurricularList

      if (item.attachments) {
        this.educationForm.attachments = [ ...item.attachments ]
      }

      if (this.propExcObjList && this.propExcObjList[item.id] && this.propExcObjList[item.id].length) {
        this.propExcObjList[item.id].map(ele => {
          if (ele.exc_video_link && typeof ele.exc_video_link === "string") {
            ele.exc_video_link = JSON.parse(ele.exc_video_link)
          }

          if (ele.exc_attach && typeof ele.exc_attach === "string") {
            ele.exc_attach = JSON.parse(ele.exc_attach)
          }
        })
        this.extraCurricularList = this.propExcObjList[item.id]
      }

      // Add School
      const findSchoolIndex = this.cvSchoolOptions.findIndex(sch => (sch.org_name + sch.org_city) === (this.educationForm.institute_name + this.educationForm.institute_city))
      if (findSchoolIndex >= 0) {
        this.studentSchool = this.cvSchoolOptions[findSchoolIndex]
      }
      else {
        this.studentSchool = {
          org_name: this.educationForm.institute_name,
          org_city: this.educationForm.institute_city,
          org_state: this.educationForm.institute_state,
          org_country: this.educationForm.institute_country
        }
        this.cvSchoolOptions.unshift(this.studentSchool)
      }

      this.editEducationShow = true
      this.addEducationShow = true
      this.showEducationModal = true
      this.editMode = true
    },
    /**
     * videoLinkValidationCheck
     * */
    videoLinkValidationCheck (videoLink) {
      if (!videoLink) {
        return "Video link can't be empty"
      }
      else if (!videoLink.includes("http")) {
        return "Video Link is not valid"
      }
      else {
        return ""
      }
    },
    /**
     * closeEducationModal
     */
    closeEducationModal () {
      this.editEducationShow = false
      this.addEducationShow = false
      this.editEducationShow = false
      this.addEducationShow = false
      this.showEducationModal = false
      this.editMode = false
      // this.$emit("emitUserProfileEducation", userEditResp.resp_data)

      if (this.educationForm.id) {
        const payload = {
          newEducation: this.educationForm,
          extraCurricularList: this.extraCurricularList
        }
        this.$emit("emitUserProfileEducation", payload)
      }
      else {
        this.$emit("emitCloseEducationModal", false)
      }
    },
    /**
     * addSubject
     */
    addSubject () {
      this.educationForm.subjects.unshift(this.vmSubjectForm)
      this.vmSubjectForm = {}
    },
    /**
     * onEducationAttatachmentChange
     **/
    onEducationAttatachmentChange (event) {
      let self = this
      const fileList = event.target.files
      if (!self.cvAttachmentObj.doc_type) {
        const ele = document.getElementById("AttachmentFileInput")
        if (ele) {
          ele.value = ""
        }
        return
      }
      if (fileList && fileList.length && self.cvAttachmentObj.doc_type) {
        for (let file of fileList) {
          if (file.type.includes("pdf")) {
            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = async (e) => {
              if (self.cvAttachFilenameWithTagObj[file.fileName]) {
                return
              }

              file.url = e.currentTarget.result
              file.fileName = file.name

              file.doc_type = self.cvAttachmentObj.doc_type
              file.doc_name = self.cvAttachmentObj.doc_name

              self.educationForm.attachments.unshift(file)

              self.cvAttachFilenameWithTagObj[file.fileName] = {
                doc_type: self.cvAttachmentObj.doc_type,
                doc_name: self.cvAttachmentObj.doc_name
              }

              const ele = document.getElementById("AttachmentFileInput")
              if (ele) {
                ele.value = ""
              }

              self.cvAttachmentObj = {}
            }
          }
          else {
            alert("Only Pdf allowed")
          }
        }
      }
    },
    /**
     * SubmitEducation
     */
    async submitEducation () {
      this.educationSubmited = true
      let valid = await this.$refs.educationForm.validate()
      if (!valid) {
        return
      }

      let errorFound = false
      if (this.educationForm.videoLinks && this.educationForm.videoLinks.length) {
        for (const videoLink of this.educationForm.videoLinks) {
          if (!videoLink || !videoLink.url.includes("http")) {
            errorFound = true
          }
        }
      }

      if (this.vmSubjectForm && Object.keys(this.vmSubjectForm).length) {
        this.educationForm.subjects.unshift({ ...this.vmSubjectForm })
        this.vmSubjectForm = {}
      }

      if (!this.educationForm.subjects.length) {
        this.educationForm.subjects.push({})
        return
      }

      
      /*
      // Not Mandatory. So Commented
      if (this.educationForm.subjects.length) {
        for (const subject of this.educationForm.subjects) {
          if (!subject.marks || !subject.name) {
            errorFound = true
          }
        }
      }
      */

      if (errorFound) {
        return
      }

      this.educationForm.institute_name = this.studentSchool.org_name
      this.educationForm.institute_city = this.studentSchool.org_city
      this.educationForm.institute_state = this.studentSchool.org_state
      this.educationForm.institute_country = this.studentSchool.org_country

      let educationForm = { ...this.educationForm }
      let attachments = [...educationForm.attachments]

      delete educationForm.attachments

      let params = {
        education: JSON.stringify(educationForm),
        attachments: attachments,
        cvAttachFilenameWithTagObj: JSON.stringify(this.cvAttachFilenameWithTagObj)
      }

      let userEditResp = await User.useProfileAdd(this, params)
      if (userEditResp.resp_status || userEditResp.resp_code === "DB_UPDATE_ERROR_NO_CHANGE") {
        this.update++
        this.vmSubjectForm = {}
        this.videoLink = null

        if (this.educationForm.id) {
          // Edit
          this.educationForm = userEditResp.new_education
        }
        else {
          this.editEducationShow = true
          this.educationForm = userEditResp.new_education
        }

        this.$emit("emitRefreshUserDetails")
      }
      
      ApiResponse.responseMessageDisplay(this, userEditResp)
    },
    /**
     * onExtraCurricularAttatachmentChange
    **/
    onExtraCurricularAttatachmentChange (event, extraCurricular, inputId) {
      const fileList = event.target.files

      if (fileList && fileList.length) {
        for (let file of fileList) {
          if (file.type.includes("pdf")) {
            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = async (e) => {
              file.url = e.currentTarget.result
              file.fileName = file.name

              if (!extraCurricular.exc_attach) {
                extraCurricular.exc_attach = []
              }

              if (typeof extraCurricular.exc_attach === "string") {
                extraCurricular.exc_attach = JSON.parse(extraCurricular.exc_attach)
              }

              extraCurricular.exc_attach.unshift(file)

              const ele = document.getElementById(inputId)
              if (ele) {
                ele.value = ""
              }
            }
          }
          else {
            alert("Only Pdf allowed")
          }
        }
      }
    },
    /**
     * addExcVideoLink
     */
    addExcVideoLink (extraCurricular) {
      if (!extraCurricular.excVideoLink) {
        return
      }

      if (!extraCurricular.exc_video_link) {
        extraCurricular.exc_video_link = []
      }

      if (typeof extraCurricular.exc_video_link === "string") {
        extraCurricular.exc_video_link = JSON.parse(extraCurricular.exc_video_link)
      }

      extraCurricular.exc_video_link.unshift({ url: extraCurricular.excVideoLink })
      extraCurricular.excVideoLink = null
    },
    /**
     * addExtraCurricularActivity
     */
    async addExtraCurricularActivity () {
      const valid = await this.$refs.extraCurrucularForm.validate()
      if (!valid) {
        return
      }

      if (!this.educationForm.id) {
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "Please Add Education first to add extra curricular activity"
        return
      }

      if (this.vmExtraCurricularForm.excVideoLink && this.vmExtraCurricularForm.excVideoLink.includes("http")) {
        this.vmExtraCurricularForm.exc_video_link.unshift({ url: this.vmExtraCurricularForm.excVideoLink })
        this.vmExtraCurricularForm.excVideoLink = null
      }

      const payload = {
        usp_edu_id: this.educationForm.id,
        ...this.vmExtraCurricularForm
      }

      let excpResp = await UserProfileExc.user_profile_excAdd(this, payload)
      if (!excpResp.resp_status) {
        ApiResponse.responseMessageDisplay(this, excpResp)
        return
      }

      excpResp.resp_data.exc_attach = JSON.parse(excpResp.resp_data.exc_attach)
      excpResp.resp_data.exc_video_link = JSON.parse(excpResp.resp_data.exc_video_link)

      this.$refs.extraCurrucularForm.reset()

      this.extraCurricularList.unshift(excpResp.resp_data)
      this.vmExtraCurricularForm = {
        exc_attach: [],
        exc_video_link: []
      }
    },
    /**
     * editExtraCurricularActivity
     */
    async editExtraCurricularActivity (extraCurricular, index) {
      const valid = await this.$refs["extraCurrucularForm" + index][0].validate()
      if (!valid) {
        return
      }

      if (extraCurricular.excVideoLink && extraCurricular.excVideoLink.includes("http")) {
        extraCurricular.exc_video_link.unshift({ url: extraCurricular.excVideoLink })
        extraCurricular.excVideoLink = null
      }

      const payload = {
        usp_edu_id: this.educationForm.id,
        ...extraCurricular
      }

      let excpResp = await UserProfileExc.user_profile_excEdit(this, payload)
      if (!excpResp.resp_status) {
        ApiResponse.responseMessageDisplay(this, excpResp)
        return
      }

      excpResp.resp_data.exc_attach = JSON.parse(excpResp.resp_data.exc_attach)
      excpResp.resp_data.exc_video_link = JSON.parse(excpResp.resp_data.exc_video_link)

      extraCurricular = excpResp.resp_data
    },
    /**
     * deleteExtraCurricularActivity
     */
    async deleteExtraCurricularActivity (extraCurricular, index) {
      let excpResp = await UserProfileExc.user_profile_excDelete(this, extraCurricular.exc_id)
      if (!excpResp.resp_status) {
        ApiResponse.responseMessageDisplay(this, excpResp)
        return
      }

      this.extraCurricularList.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.instituteTitle {
  text-transform: uppercase;
}
.EducationForm {
  .addIcon {
    position: absolute;
    right: 10px;
  }
}
.educationCard {
  text-transform: capitalize;
 .educationTitle1 {
   font-weight: 500;
   span {
      font-weight: 300;
      color: var(--iq-dark);
   }
 }
}
.educationAddIcon {
  float: right;
}
.markRow {
  .addIcon {
    float: right;
    margin-top: -42px;
    font-size: 20px;
    position: relative;
    left: 26px;
    cursor: pointer;
  }
}
.subjectList {
  .closeIcon {
    position: absolute;
    right: 0;
    font-size: 17px;
  }
}
.imageCol {
  height: auto;
  margin-top: 13px !important;
  border: 1px solid;
  padding-bottom: 18px !important;
  img {
    height: 110px;
    width: 100%;
    object-fit: contain;
    border: 2px solid aliceblue;
  }
  .closeIcon {
    position: absolute;
    right: 0;
    top: -13px;
    font-size: 20px;
    z-index: 99;
    color: var(--iq-primary);
    fill: var(--iq-primary);
    background: white;
    border-radius: 50%;
    height: 29px;
    cursor: pointer;
  }
}
.ResendSpan {
  color: var(--iq-primary);
  cursor: pointer;
  float: right;
}
.metaPreviewCol {
  // height: 64px;
  // width: 64px;
  height: 108px;
  width: 153px;
  border: 2px solid aliceblue;
  margin-right: 11px;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
.p-18 {
  padding: 16px !important;
  height: 124px;
}
.fa-solid {
  color: var(--iq-primary);
}
.deleteIconSpan {
  margin-left: 15px;
}
.attachmentAddIcon {
  font-size: 18px;
  position: absolute;
  top: 1px;
  cursor: pointer;
  z-index: 99;
  left: 66px;
}
.attachmentCol {
  img {
    height: 32px;
    width: 32px;
    object-fit: contain;
    border: 2px solid aliceblue;
    margin-bottom: 6px;
  }
}

.fa-solid {
  background: white;
  border-radius: 50%;
}
.highZindex {
  z-index: 999;
}
table {
  .headerRow {
    border-bottom: 1px solid var(--iq-border-light);
    height: 45px;
  }
}
hr {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.viewEducationCard {
  .iq-card-body {
    padding-top: 2px;
  }
}
.extraCloseIcon {
  font-size: 18px;
  position: absolute;
  top: 22px;
  cursor: pointer;
  z-index: 99;
  margin-left: -9px;
}
.rightAlignDiv {
  justify-content: end;
  button {
    position: relative;
    left: 25px;
  }
}
.noVideoLinkIcon {
  font-size: 80px;
  margin-top: 10px;
  color: grey;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
